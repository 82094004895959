import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export default function Submit({ label = 'Save' }) {
	const submitRef = useRef(null);

	const onKeyDown = (e) => {
		if (e.key === 's' && e.metaKey && submitRef && submitRef.current) {
			e.preventDefault();
			submitRef.current.click();
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', onKeyDown);

		return () => {
			window.removeEventListener('keydown', onKeyDown);
		};
	}, []);

	return (
		<button className="formosa-button formosa-button--success" form="form" ref={submitRef} type="submit">{label}</button>
	);
}

Submit.propTypes = {
	label: PropTypes.string,
};
