export const familyEventTypes = {
	MARR: 'marriage',
	ANUL: 'annulment',
	CENS: 'census',
	DIV: 'divorce',
	DIVF: 'divorce filed',
	ENGA: 'engagement',
	EVEN: 'event',
	MARB: 'marriage bann',
	MARC: 'marriage contract',
	MARL: 'marriage license',
	MARS: 'marriage settlement',
};

export const personFactTypes = {
	GEND: 'gender',
	CAST: 'caste',
	DSCR: 'physical description',
	EDUC: 'education',
	FACT: 'fact',
	IDNO: 'identification number',
	NATI: 'nationality',
	NCHI: 'children count',
	NMR: 'marriage count',
	OCCU: 'occupation',
	PROP: 'property',
	RELI: 'religion',
	RESI: 'residence',
	SSN: 'social security number',
	TITL: 'title',
};

export const personEventTypes = {
	BIRT: 'birth',
	DEAT: 'death',
	ADOP: 'adoption',
	BAPM: 'baptism',
	BARM: 'bar mitzvah',
	BASM: 'bas mitzvah',
	BLES: 'blessing',
	BURI: 'burial',
	CENS: 'census',
	CHR: 'christening',
	CHRA: 'adult christening',
	CONF: 'confirmation',
	CREM: 'cremation',
	EMIG: 'emigration',
	EVEN: 'event',
	FCOM: 'first communion',
	GRAD: 'graduation',
	IMMI: 'immigration',
	NATU: 'naturalization',
	ORDN: 'ordination',
	PROB: 'probate',
	RETI: 'retirement',
	WILL: 'will',
};

export const relationshipTypes = [
	'Natural',
	'Adopted',
	'Step',
	'Foster',
	'Related',
	'Guardian',
	'Sealed',
	'Private',
	'Unknown',
];
