import Children from '../Tabs/Children';
import Events from '../Tabs/Events';
import Fields from './Fields';
import Notes from '../../../Components/Notes';
import PropTypes from 'prop-types';
import React from 'react';
import Sources from '../Tabs/Sources';
import TabPanel from '../../../Components/TabPanel';
import Tabs from '../../../Components/Tabs';

export default function PlaceTabs({
	hasChildren = false,
	hasEvents = false,
	hasSources = false,
	row,
	setRow,
}) {
	return (
		<Tabs defaultTab="basics" title={row.id ? row.meta.full_name : ''} updateUrl>
			<TabPanel id="basics" label="Basics">
				<Fields />
			</TabPanel>
			<TabPanel id="notes" label={`Notes (${row.notes.length})`}>
				<Notes row={row} setRow={setRow} type="places" />
			</TabPanel>
			{hasChildren && (
				<TabPanel id="children" label={`Children (${row.children.length})`}>
					<Children row={row} />
				</TabPanel>
			)}
			{hasEvents && (
				<TabPanel id="events" label={`Events (${row.events.length})`}>
					<Events row={row} />
				</TabPanel>
			)}
			{hasSources && (
				<TabPanel id="sources" label={`Sources (${row.sources.length})`}>
					<Sources row={row} />
				</TabPanel>
			)}
		</Tabs>
	);
}

PlaceTabs.propTypes = {
	hasChildren: PropTypes.bool,
	hasEvents: PropTypes.bool,
	hasSources: PropTypes.bool,
	row: PropTypes.object.isRequired,
	setRow: PropTypes.func.isRequired,
};
