import { NavLink, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

export default function PersonFamilies({ row, setShowAddFamilyModal }) {
	const { tree } = useParams();

	const partners = row.husbands.concat(row.wives);
	const children = partners.map((s) => (
		s.children.map((child) => ({ ...s, child }))
	)).flat();

	const addParents = (e) => {
		setShowAddFamilyModal({
			e,
			title: 'Parents',
			defaultRow: {
				children: [row],
			},
		});
	};

	const addPartner = (e) => {
		const genders = row.facts.filter((f) => (f.subtype === 'GEND'));
		let type = 'husband';
		if (genders.length > 0 && genders[0].fact_value === 'f') {
			type = 'wife';
		}
		setShowAddFamilyModal({
			e,
			title: 'Partner',
			defaultRow: {
				[type]: row,
			},
		});
	};

	return (
		<>
			<div className="heading">
				<h2 className="heading__title">Parents</h2>
				<div className="heading__buttons">
					<button className="formosa-button button--success-outline button--small" onClick={addParents} type="button">Add Parents</button>
				</div>
			</div>
			<ul>
				{row.parents.map((r) => {
					const relation = r.children.find((c) => (c.person.id === row.id));
					const name = [r.husband.meta.name];
					if (relation.frel !== 'Natural') {
						name.push(` (${relation.frel})`);
					}

					return (
						<li key={r.id}>
							<NavLink to={`/trees/${tree}/families/${r.id}`}>
								{`${r.husband.meta.name} & ${r.wife.meta.name}`}
							</NavLink>
						</li>
					);
				})}
			</ul>

			<div className="heading">
				<h2 className="heading__title">{`Partners (${partners.length})`}</h2>
				<div className="heading__buttons">
					<button className="formosa-button button--success-outline button--small" onClick={addPartner} type="button">Add Partner</button>
				</div>
			</div>
			<ul>
				{partners.map((r) => {
					const partner = r.husband || r.wife;
					return (
						<li key={r.id}>
							<NavLink to={`/trees/${tree}/families/${r.id}`}>{partner.meta.name}</NavLink>
						</li>
					);
				})}
			</ul>

			<h2>{`Children (${children.length})`}</h2>
			<ul>
				{children.map((r) => {
					const relation = r.wife ? r.child.frel : r.child.mrel;
					return (
						<li key={r.child.id}>
							<NavLink to={`/trees/${tree}/families/${r.id}`}>{r.child.person.meta.name}</NavLink>
							{relation !== 'Natural' ? ` (${relation})` : null}
						</li>
					);
				})}
			</ul>
		</>
	);
}

PersonFamilies.propTypes = {
	row: PropTypes.object.isRequired,
	setShowAddFamilyModal: PropTypes.func.isRequired,
};
