import PropTypes from 'prop-types';
import React from 'react';

export default function TabButton({ currentTab, id, label, goToTab, updateUrl = false }) {
	const onClick = (e) => {
		// Ignore the click if alt/ctrl/option/shift key was being pressed at the same time.
		if (e.altKey || e.ctrlKey || e.metaKey || e.shiftKey) {
			return;
		}
		goToTab(id);
	};

	if (updateUrl) {
		return (
			<a
				aria-controls={id}
				aria-selected={currentTab === id}
				className="formosa-button tab-button"
				href={`#${id}`}
				id={`tab-${id}`}
				onClick={onClick}
				role="tab"
				tabIndex={currentTab === id ? 0 : -1}
			>
				{label}
			</a>
		);
	}

	return (
		<button
			aria-controls={`${id}`}
			aria-selected={currentTab === id}
			className="formosa-button tab-button"
			id={`tab-${id}`}
			onClick={onClick}
			role="tab"
			tabIndex={currentTab === id ? 0 : -1}
			type="button"
		>
			{label}
		</button>
	);
}

TabButton.propTypes = {
	currentTab: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	goToTab: PropTypes.func.isRequired,
	updateUrl: PropTypes.bool,
};
