import { React, useEffect } from 'react';
import PropTypes from 'prop-types';

export default function MetaTitle({
	children = null,
	hideTitleText = false,
	icon = null,
	title = '',
}) {
	useEffect(() => {
		let metaTitle = title;
		if (process.env.REACT_APP_TITLE) {
			if (metaTitle) {
				metaTitle += ' | ';
			}
			metaTitle += process.env.REACT_APP_TITLE;
		}
		document.querySelector('title').innerText = metaTitle;
	}, [title]);

	if (hideTitleText) {
		return null;
	}

	return (
		<div className="heading">
			{icon ? (<div className="heading__icon">{icon}</div>) : null}
			<h1 className="heading__title">{title}</h1>
			{children ? (<div className="heading__buttons">{children}</div>) : null}
		</div>
	);
}

MetaTitle.propTypes = {
	children: PropTypes.node,
	icon: PropTypes.node,
	hideTitleText: PropTypes.bool,
	title: PropTypes.string,
};
